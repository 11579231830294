import AutoNumeric from 'autonumeric';
import Rails from "@rails/ujs"
import 'bootstrap/js/dist/tab';
require('twitter-bootstrap-wizard')
require("select2")
require("select2/dist/css/select2")
import { setupSelectEvents} from "src/address_selector/utils"
import validate from 'jquery-validation'
if (document.documentElement.lang === "zh-HK") {
  require("jquery-validation/dist/localization/messages_zh_TW")
}
import gtag, { install } from 'ga-gtag';
install('G-VZZCC8MLD1');


// document.addEventListener("turbolinks:load", function() {
//   if (document.getElementById("wizard-loan_amount")) {
//       window.loan_amount_ele = new AutoNumeric('#wizard-loan_amount', { "currencySymbol" : 'HK$', "currencySymbolPlacement"    : AutoNumeric.options.currencySymbolPlacement.prefix,
//   decimalPlaces: AutoNumeric.options.decimalPlaces.none, unformatOnSubmit: true, formatOnPageLoad: true}); 

//   }
//   // if (window.loan_amount_ele == undefined) {
//   // }
//   // if (window.monthly_mortgage_repayment_or_rent_ele == undefined) {
//   if (document.getElementById("wizard-monthly_mortgage_repayment_or_rent")) {
//       window.monthly_mortgage_repayment_or_rent_ele = new AutoNumeric('#wizard-monthly_mortgage_repayment_or_rent', { "currencySymbol" : 'HK$', "currencySymbolPlacement"    : AutoNumeric.options.currencySymbolPlacement.prefix,
//       decimalPlaces: AutoNumeric.options.decimalPlaces.none, unformatOnSubmit: true, formatOnPageLoad: true}); 
//     }
//   // if (window.monthly_income_ele == undefined) {
//   if (document.getElementById("wizard-monthly_income")) {
//     window.monthly_income_ele = new AutoNumeric('#wizard-monthly_income', { "currencySymbol" : 'HK$', "currencySymbolPlacement"    : AutoNumeric.options.currencySymbolPlacement.prefix,
//     decimalPlaces: AutoNumeric.options.decimalPlaces.none, unformatOnSubmit: true, formatOnPageLoad: true}); 
//   }
// 	$('#wizard-loan_amount').on("focusout", () => {
// 		var ele = $('#wizard-loan_amount');
// 		var val = ele.val().substring(3, ele.val().length);
// 		val = replaceAll(val,',',''); 

// 		if (val < 5000) {
// 			val = 5000;
// 		} else if (val > 600000) {
// 			val = 600000;
// 		}
// 		window.loan_amount_ele.set(Math.round(val/1000)*1000);

// 	})
// });

// Loan amount


function replaceAll(str, find, replace) {
	return str.replace(new RegExp(find, 'g'), replace);
}

// HKID
document.addEventListener("turbolinks:load", function() {
	var $input = $("#wizard-hkid_no");
	$input.on( "keyup", function( event ) {
		// When user select text in the document, also abort.
		var selection = window.getSelection().toString();
		if ( selection !== '' ) return;
		
		var $this = $( this );
		
		// Get the value.
		var input = $this.val();
		
		// remove all symbols from user input
		var input = input.replace(/[^\d\w()]+/g, "");
		input = input.toUpperCase();

    // add brackets when a complete HKID is presented
		if (/^[A-Z]{1,2}[0-9]{6}[0-9,A]$/g.test(input)) {
			if (input.length == 8) {
				input = input.slice( 0, 7 ) + '(' + input[7] + ')'
			} else {
				input = input.slice( 0, 8 ) + '(' + input[8] + ')'
			}
		}

		$this.val( function() {
			return ( input === 0 ) ? "" : input.toLocaleString( "en-US" );
		} );
	} );
});

/*-----------------------------------------------
|   Jquery Validation
-----------------------------------------------*/
function hkidCheck(id) {
  if (/^[A-Z]{1,2}[0-9]{6}\([0-9,A]\)$/g.test(id)) {
    if (id.length == 10) {
      id = " " + id;
    }

    var i;
    var sum = 0;
    var reminder;
    var trimmed_id = id.replace(id.substring(id.length-3, id.length), "")
   
    for(i = 0;i < 8;i++) {
      sum += hkidValue(trimmed_id[i]) * (9 - i)
    }

    reminder = sum % 11
    
    if (reminder == 0) {
      return parseInt(id[9]) == 0
    } else if (reminder == 1) {
      return id[9] == 'A'
    } else {
      return (11-reminder) == parseInt(id[9])
    }
  }
  return false; 
}

function hkidValue(char) {
  if (char == ' ') {
    return 36
  } else if (/^[A-Z]$/g.test(char)) {
    return char.charCodeAt(0) - 55
  } else {
    return parseInt(char)
  }
}

$(function () {
  var forms = $('.form-validation');
  var hkid_error_msg = "HKID is invalid: Please enter a valid HKID, e.g. Z683365(5).";
  if (document.documentElement.lang == "zh-HK") {
    hkid_error_msg = "香港身份証號格式不正確：請輸入正確的身份格式, 例如： Z683365(5)";
  }
  var loan_amount_error_msg = "Loan amount should range between HK$5,000 to HK$600,000";
  if (document.documentElement.lang == "zh-HK") {
    loan_amount_error_msg = "貸款金額應介乎 HK$5,000 至 HK$600,000";
  }
  // HKID validation rule
  $.validator.addMethod("hkid", function(value, element) {
      // var hkid_without_bracket = value.replace("(", "").replace(")", "");
      return this.optional(element) || hkidCheck(value);
  }, hkid_error_msg);
  $.validator.addMethod("loan_amount", function(value, element) {
      var number = value.replace("HK$", "").replace(",", "");

      return this.optional(element) || (number >=5000 && number <= 600000) ;
  }, loan_amount_error_msg);
  forms.length && forms.each(function (index, value) {
    var $this = $(value);
    
    var options = $.extend({
      ignore: ".jvalidate-ignore",
      errorPlacement: function (error, element) {
        if (element.parent('.input-group').length) { 
            error.insertAfter(element.parent());      // radio/checkbox?
        } else if (element.hasClass('select2-hidden-accessible')) {     
            error.insertAfter(element.next('span'));  // select2
        } else {                                      
            error.insertAfter(element);               // default
        }
      }

    }, $this.data('options'));
    $this.validate(options);
  });

});



/*-----------------------------------------------
|   Select2
-----------------------------------------------*/
document.addEventListener("turbolinks:before-cache", function () {
  if ($('.selectpicker').first().data('select2') != undefined) $('.selectpicker').select2('destroy');
});

document.addEventListener("turbolinks:load", function() {
  var select2 = $('.selectpicker');
  select2.length && select2.each(function (index, value) {
    var $this = $(value);
    var options = $.extend({
      theme: 'bootstrap4'
    }, $this.data('options'));
    $this.select2(options);
  });

  setupSelectEvents();
  $('.selectpicker').on('change', function() {
    $(this).valid();
});
  var locale = "en_US";
  if (document.documentElement.lang == "zh-HK") {
    locale = "zh_TW";
  }
    var placeholder = "Please input estate / building";
    if (locale != 'en_US') {
      placeholder = "請輸入屋苑或大厦名稱";
    }
    var language = "en";
  if (locale != 'en_US') {
    language = "zh-TW";
  }
  $('#street_address').select2({
    placeholder: placeholder,
    minimumInputLength: 1,
    ajax: {
      language: language,
      url: 'https://ekycms.smartcashhk.com/api/address_lookup',
      dataType: 'json',
      data: function (params) {
        console.log(`street_address data: ${JSON.stringify(params) }`);
        console.log(`street_address lang: ${JSON.stringify(language) }`);
        var query = {
          query: params.term
        }

        // Query parameters will be ?search=[term]&type=public
        return query;
      },
      processResults: function (data) {
        console.log(`street_address processResults: ${JSON.stringify(data)}`);
        // Transforms the top-level key of the response object from 'items' to 'results'
        var addresses = [];
        if (locale == 'en_US') {
          for (var i = 0; i< data.results.length; i++) {
            addresses.push({
              id: data.results[i].english_address,
              text: data.results[i].english_address
            })
          }
        } else {
          for (var i = 0; i< data.results.length; i++) {
            addresses.push({
              id: data.results[i].english_address,
              text: data.results[i].chinese_address
            })
          }
        }
        console.log(`street_address processResults: ${JSON.stringify(addresses)}`);
        return {
          results: addresses
        };
      }
      // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
    }
  });
});

/*-----------------------------------------------
|   Bootstrap Wizard
-----------------------------------------------*/

document.addEventListener("turbolinks:load", function() {
  var Selector = {
    DATA_WIZARD: '[data-wizard]',
    PREVIOUS_BUTTON: '.previous .btn',
    TAB_PANE: '.tab-pane',
    FORM_VALIDATION: '.form-validation',
    NAV_ITEM_CIRCLE: '.nav-item-circle',
    NAV_ITEM: '.nav-item',
    NAV_LINK: '.nav-link',
    WIZARD_LOTTIE: '.wizard-lottie'
  };
  var ClassName = {
    ACTIVE: 'active',
    DONE: 'done',
    NAV: 'nav'
  };
  var DATA_KEY = {
    OPTIONS: 'options',
    WIZARD_STATE: 'wizard-state',
    CONTROLLER: 'controller',
    ERROR_MODAL: 'error-modal'
  };
  var wizards = $(Selector.DATA_WIZARD);

  var isFormValidate = function isFormValidate($currentTab) {
    var $currentTabForms = $currentTab.find(Selector.FORM_VALIDATION);
    var isValidate = true;
    $currentTabForms.each(function (i, v) {
      isValidate = $(v).valid();
      return isValidate;
    });
    return isValidate;
  };

  var submitForm = (currentTab) => {
  	switch (currentTab.attr("id")) {
		case "update_loan_information_tab":
			var form = document.querySelector('form#update_loan_information_form');
			Rails.fire(form, 'submit');
			break;
		case "update_personal_information_tab":
			var form = document.querySelector('form#update_personal_information_form');
			Rails.fire(form, 'submit');
			break;
		case "update_address_information_tab":
			var form = document.querySelector('form#update_address_information_form');
			Rails.fire(form, 'submit');
			break;
		case "update_work_information_tab":
			// $("#update_loan_information_form").trigger('submit.rails')
			var form = document.querySelector('form#update_work_information_form');
			Rails.fire(form, 'submit');
			break;
		default:
			// statements_def
			break;
	}
  }

  !!wizards.length && wizards.each(function (index, value) {
    var $this = $(value);
    var controller = $this.data(DATA_KEY.CONTROLLER);
    var $controller = $(controller);
    var $buttonPrev = $controller.find(Selector.PREVIOUS_BUTTON);
    var $modal = $($this.data(DATA_KEY.ERROR_MODAL));
    var $lottie = $(value).find(Selector.WIZARD_LOTTIE);
    var options = $.extend({
      container: value.querySelector(Selector.WIZARD_LOTTIE),
      renderer: 'svg',
      loop: true,
      autoplay: false,
      name: 'Hello World'
    }, $lottie.data(DATA_KEY.OPTIONS));
    var animation = window.bodymovin.loadAnimation(options);
    $this.bootstrapWizard({
      tabClass: ClassName.NAV,
      onNext: function onNext(tab, navigation, idx) {
        var $currentTab = $this.find(Selector.TAB_PANE).eq(idx - 1);
        // var $currentTab = tab;
        if (isFormValidate($currentTab)) {
        	submitForm($currentTab)
        	return true
        } else {
        	return false;
        }
        
      },
      onTabClick: function onTabClick(tab, navigation, idx, clickedIndex) {
        return false
        var stepDone = $this.find(".nav-item:nth-child(" + (clickedIndex + 1) + ") .nav-link").data(DATA_KEY.WIZARD_STATE);
        if (clickedIndex > idx) {
          return false
        }
        // return true
        if (stepDone === 'done') {
          $modal.modal('show');
          return false;
        }

        // if (clickedIndex <= idx) {
        //   return true;
        // }

        var isValid = true;
        $this.find(Selector.TAB_PANE).each(function (tabIndex, tabValue) {
          if (tabIndex < clickedIndex) {
            $this.bootstrapWizard('show', tabIndex);
            isValid = isFormValidate($(tabValue));
          }

          return isValid;
        });
        return isValid;
      },
      onTabShow: function onTabShow(tab, navigation, idx) {
        var length = navigation.find('li').length - 1;
        idx === 0 ? $buttonPrev.hide() : $buttonPrev.show();
        idx === length && setTimeout(function () {

          return animation.play();
        }, 300);
        $this.find(Selector.NAV_LINK).removeClass(ClassName.DONE);
        $this.find(Selector.NAV_ITEM).each(function (i, v) {
          var link = $(v).find(Selector.NAV_LINK);

          // Application Review Page
          if (idx === length && !link.hasClass(ClassName.ACTIVE)) {
            link.attr('data-wizard-state', 'done');
            
            
          }

          if (!link.hasClass(ClassName.ACTIVE)) {
            link.addClass(ClassName.DONE);
            return true;
          }

          if (idx === length) {
            link.addClass(ClassName.DONE);
            $controller.hide();
          }

          if (idx < length) {
            $controller.show();
            window.scrollTo(0, 0);
          }


          return false;
        });
      }
    });
  });
});


document.addEventListener("turbolinks:load", function() {
  $("#update_loan_information_form").on("ajax:success", (event) => {
  	gtag('event', 'update_loan_information', {"user_id": $("input[name='user_id']").val()});
      console.log("success")
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
  	console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  $("#update_personal_information_tab").on("ajax:success", (event) => {
  	gtag('event', 'update_personal_information', {"user_id": $("input[name='user_id']").val()});
      console.log("success")
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
  	console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });
  $("#update_address_information_form").on("ajax:success", (event) => {
      console.log("success")
      // console.log(event.detail[0])
      // $("#application_residential_address").val(event.detail[0])
      // var form = document.querySelector('form#update_address_information_form');
      gtag('event', 'update_address_information', {"user_id": $("input[name='user_id']").val()});
      // Rails.fire(form, 'submit');
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
    console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  $("#update_work_information_form").on("ajax:success", (event) => {
      console.log("success")
      gtag('event', 'update_work_information', {"user_id": $("input[name='user_id']").val()});
      // $("#update_loan_information_form").append xhr.responseText
  }).on("ajax:error", (event) => {
  	console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  $("#submit_application_form").on("ajax:success", (event) => {
      gtag('event', 'submit_application_form', 
      	{
      		"user_id": $("input[name='user_id']").val(),
      		"requested_loan_amount": window.loan_amount_ele.get(),
      		"requested_tenor": $("#wizard-tenors").data('select2').val(),
      	});
  }).on("ajax:error", (event) => {
  	console.log("error")
      // $("#update_loan_information_form").append "<p>ERROR</p>"
  });

  
});
